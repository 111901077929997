
export default {
    name: "ImageBlok",
    props: {
        blok: {
            type: Object,
            required: true,
        },
    },
    computed: {
        videoSrc() {
            return this.blok.vimeoSrc || this.blok.video?.filename
        },
        altText() {
            return this.blok.alt || this.blok.video?.alt || "video"
        },
        style() {
            return {
                maxHeight: this.blok.maxHeight ?? undefined,
                maxWidth: this.blok.maxWidth ?? undefined,
                height: this.blok.height ?? undefined,
                width: this.blok.width ?? undefined,
                objectFit: this.blok.fit,
                objectPosition: this.blok.position,
                clipPath: this.blok.borderFix ? "inset(1px 1px)" : undefined,
            }
        },
    },
}
